<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>{{ $t("message.commands.commands") }}</b-card-title>
      <b-card-sub-title>{{
        $t("message.commands.beSureToDragOrClickOnTheBoxToSendAJsonFile")
      }}</b-card-sub-title>
    </b-card-body>
    <b-card>
      <div>
        <div>
          <b-row>
            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label">{{
                  $t("message.commands.archive")
                }}</label>
                <b-form-file
                  v-model="file"
                  :state="Boolean(file)"
                  @change="onFileChange"
                  accept="application/json"
                  placeholder="Escolha um arquivo ou solte-o aqui..."
                  drop-placeholder="Solte o arquivo aqui..."
                  style="heigth: 20px"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="mb-3">
                <label class="form-label">{{
                  $t("message.commands.result")
                }}</label>
                <b-form-textarea
                  type="text"
                  rows="10"
                  style="height: 100%"
                  v-model="openFile"
                  readonly
                ></b-form-textarea>
              </div>
            </div>
          </b-row>

          <div class="mb-3">
            <label class="form-label">{{
              $t("message.commands.operation")
            }}</label>
            <v-select
              :reduce="(option) => option.value"
              v-model="type"
              :options="typeOptions"
            />
          </div>
        </div>
      </div>
      <br />
      <div class="text-right">
        <b-button v-on:click="uploadJson()" variant="primary">{{
          $t("message.commands.send")
        }}</b-button>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BButton,
  BFormFile,
  BFormTextarea,
  BRow,
  BFormSelect,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import store from "@/store";

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BButton,
    BFormFile,
    BFormTextarea,
    BRow,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      accessControlAdministrative: [],
      accessControlFinancial: [],
      accessControlManager: [],
      accessControlUser: [],
      accessControlDirector: [],
      accessControlAdministrators: [],
      accessControlCommercial: [],

      file: null,
      openFile: null,
      type: "",
      jsonData: null,
      fileObject: null,

      //Select
      typeOptions: [],

      userData: store.state.user.userData,
    };
  },

  async created() {
    var typeList = [];
    this.userData = store.state.user.userData;
    await axios
      .get(`${URL_API}command_type`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].type;
          typeList.push(element);
          this.typeOptions.push({ label: typeList[i], value: typeList[i] });
        }
      });
  },

  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.readFile(files[0]);
    },

    readFile(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.openFile = e.target.result;
        let json = JSON.parse(e.target.result);
      };
      reader.readAsText(file);
    },

    uploadJson() {
      var fileName = this.file.name;
      var formattedName = fileName.slice(0, -5);
      var type = this.type;

      let self = this;

      var fr = new FileReader();

      fr.onload = function (e) {
        var resultFormatted = e.target.result.substring(
          1,
          e.target.result.length - 1
        );
        this.jsonData = resultFormatted;

        this.fileObject = [
          { data: this.jsonData, fileName: formattedName, type: type },
        ];
        const userData = store.state.user.userData
        console.log("this.userData", userData)

        axios({
          method: "post",
          url: `${URL_API}command`,
          headers: getHeader(userData),
          data: this.fileObject[0],
        }).then((response) => {
          self.file = null;
          self.openFile = null;
          self.type = "";

          self.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Alterações salvas!`,
              icon: "CheckIcon",
              variant: "success",
              text: `As Alterações foram salvas com sucesso`,
            },
          });
        });
      };
      fr.readAsText(this.file);
    },
  },
};
</script>

<style></style>
